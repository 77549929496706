import React from "react";
import backgroundImage from "./Photo/Stone/GP1.JPG";
import gp1m from "./Photo/Mix/GP1M.JPG";
import gp2m from "./Photo/Mix/GP2M.JPG";
import gp3m from "./Photo/Mix/GP3M.JPG";
import gp4m from "./Photo/Mix/GP4M.JPG";
import gp5m from "./Photo/Mix/GP5M.JPG";
import gp6m from "./Photo/Mix/GP6M.JPG";
import gp7m from "./Photo/Mix/GP7M.JPG";
import gp8m from "./Photo/Mix/GP8M.JPG";
import gp9m from "./Photo/Mix/GP9M.JPG";

import gp1 from "./Photo/Stone/GP1.JPG";
import gp2 from "./Photo/Stone/GP2.JPG";
import gp3 from "./Photo/Stone/GP3.JPG";
import gp4 from "./Photo/Stone/GP4.JPG";
import gp5 from "./Photo/Stone/GP5.JPG";
import gp6 from "./Photo/Stone/GP6.JPG";
import gp7 from "./Photo/Stone/GP7.JPG";
import gp8 from "./Photo/Stone/GP8.JPG";
import gp9 from "./Photo/Stone/GP9.JPG";


import "./Home.css"
const Jewellery = () => {
  return (
    <>
    {/* <section class="text-gray-600 body-font">
        <div class="container px-5 md:px-28 pt-24 mx-auto">
          <div class="flex flex-wrap w-full mb-20">
            <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 class="sm:text-5xl text-5xl font-medium title-font font-serif mb-2 text-gray-900">
                Gem Stones
              </h1>
              <div class="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
          </div>
          <div class="flex-container flex-wrap justify-center">
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp1}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp2}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp3}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp5}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp6}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp7}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp8}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp9}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp2}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 md:px-28 py-24 mx-auto">
          <div class="flex-container flex-wrap justify-center">
          
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp1m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp2m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp3m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp4m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp5m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp6m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp7m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp8m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp9m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </section> */}
      
        


            {/* <div class="lg:w-1/2 w-full mb-6 lg:mb-0 pt-20">
              <h1 class="sm:text-5xl text-5xl font-medium title-font font-serif mb-2 text-gray-900">
              Comming Soon 
              </h1>
              
            </div> */}

            {/* <div class="flex justify-center items-center h-screen">
  <div class="lg:w-1/2 w-full mb-6 lg:mb-0">Your content here</div>
</div> */}

 <div class="flex justify-center items-center h-screen md:pl-20 ">
  <div class="lg:w-1/2 w-full mb-6 lg:mb-0 md:pl-20 pl-4">
  <h1 class="sm:text-7xl md:text-7xl text-6xl font-medium title-font font-serif mb-2 text-gray-900">
              Coming Soon 
              </h1>
  </div>
</div> 





      
     
    </>
  );
};



export default Jewellery