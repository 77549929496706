import React from "react";
import backgroundImage from "./Photo/Stone/GP1.JPG";
import gp1m from "./Photo/Mix/GP1M.JPG";
import gp2m from "./Photo/Mix/GP2M.JPG";
import gp3m from "./Photo/Mix/GP3M.JPG";
import gp4m from "./Photo/Mix/GP4M.JPG";
import gp5m from "./Photo/Mix/GP5M.JPG";
import gp6m from "./Photo/Mix/GP6M.JPG";
import gp7m from "./Photo/Mix/GP7M.JPG";
import gp8m from "./Photo/Mix/GP8M.JPG";
import gp9m from "./Photo/Mix/GP9M.JPG";

import gp1 from "./Photo/Stone/GP1.JPG";
import gp2 from "./Photo/Stone/GP2.JPG";
import gp3 from "./Photo/Stone/GP3.JPG";
import gp4 from "./Photo/Stone/GP4.JPG";
import gp5 from "./Photo/Stone/GP5.JPG";
import gp6 from "./Photo/Stone/GP6.JPG";
import gp7 from "./Photo/Stone/GP7.JPG";
import gp8 from "./Photo/Stone/GP8.JPG";
import gp9 from "./Photo/Stone/GP9.JPG";
//import gp1 from "./Photo/Stone/GP1.JPG";

import "./Home.css"
const Gems = () => {
  return (
    <>
    <section class="text-gray-600 body-font">
        <div class="container px-5 md:px-28 pt-24 mx-auto">
          <div class="flex flex-wrap w-full mb-20">
            <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 class="sm:text-5xl text-5xl font-medium title-font font-serif mb-2 text-gray-900">
                Gem Stones
              </h1>
              <div class="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
          </div>
          <div class="flex-container flex-wrap justify-center">
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp1}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated (IGI)
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp2}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 2
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Zambian Emerald
                </h2>
                <p class="leading-relaxed text-base">
                 <br /> Weight : 4.46 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp3}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 3
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                African Hessonite Garnet
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 17.95 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 4
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Sri Lankan Blue Sapphire
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 9.23 cts <br /> Heated (IIGJ)
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp5}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 5
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Japanese Coral
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 19.58 cts <br />  Untreated <br /> Origin subject to testing
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp6}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 6
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Australian Opal
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 7.05 cts (Pair) <br /> Unheated and Untreated <br /> Play of colours both side
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp7}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 7
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                South Sea Pearl
                </h2>
                <p class="leading-relaxed text-base">
                 <br /> Weight : 23.64 cts <br /> Cultivated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp8}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 8
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Sri Lankan Yellow Sapphire
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 8.03 cts <br /> Unheated and Untreated (IIGJ)
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp9}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 9
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Sri Lankan White Sapphire
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 5.22 cts <br /> Unheated and Untreated (IIGJ)
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 md:px-28 py-24 mx-auto">
          <div class="flex-container flex-wrap justify-center">
          
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp1m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Affrican Natual Ruby 
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 1 - 4 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp2m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 2 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                South Sea Pearls 
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 5 - 15 cts <br /> Cultivated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp3m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 3 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                African Hessonite Garnet
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 5 - 20 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp4m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 4 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Australian Opal
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 5 - 25 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp5m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 5 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Zambian Emerald 
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 2 - 8 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp6m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 6 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Italian Natual Coral
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 5 - 25 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp7m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 7 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Madagascar Ruby 
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 10 - 20 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp8m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 8 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Zambian Emerald
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 4-8 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/3 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-72	 rounded w-full object-cover object-center mb-6"
                  src={gp9m}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 9 Mix
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Sri Lankan Sapphire 
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3 - 9 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Gems;
