import React from "react";
import photo from "./Photo/Profile.png"

const About = () => {
  return (
    <>
      <section class="text-gray-600 body-font">
        <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="font-serif title-font sm:text-5xl text-5xl mb-4 font-medium text-gray-900">
              About us
              <br class="hidden lg:inline-block" />
            </h1>
            <div class="h-1 w-20 mb-10 bg-indigo-500 rounded"></div>
            <p class="mb-8 font-serif text-base leading-relaxed">
            Shri Govind Devji Gems was born 15 years back, as a humble beginning of a young man’s dream to set up his own business around his father’s business ethos.  After finishing his higher studies in Finance, Kamal Kishore Agarwal, joined his father’s timber business at a tender age of 21. Mr. M.L.Agarwal, his father, was renowned in his domain as a man of word, who would deliver the promised unmatchable quality timber at the set fair price and on assured time. <br /> <br /> 

Having learnt these two virtues of business - quality and fair pricing, Kamal embarked on his own journey to setup his business in Gemstone trading in 2008. He chose this field to fulfil his inner desire of bringing engineering, creativity and product identification together. He decided to setup his base in Jaipur due to the city’s legacy in gemstone trading in India & the world. Learning the basics well is key to understanding any product, more so in a field like jewellery where slightest technical difference can have a big impact on a product’s pricing. In 2007, Kamal gained his professional credentials in the field by completing Diploma in Gem Identification from Gem Testing Laboratory in Jaipur, India. To add to his professional accolade, in 2008, he accomplished Diploma in Gemmology with Merit, from Gem-A, United Kingdom. Kamal’s eye for the smallest of details and professional knowledge sharpened his skillset in stone identification. His astounding memory in remembering his stock helped him advance in business with prompt product delivery. <br /> <br /> 

By god’s grace, parents’ blessings, family support and his persistent hard work, he has established his name well jewellery and gemstones trading locally. The business has grown organically from just a stock of few 100 stones to inventory of wide range of gemstones & jewellery and is now backed by a team of enthusiastic people managing different roles.<br /> <br /> 

The next step is to fuel the business growth by entering into the exports segment. Recently, in Sep‘23, Shri Govind Devji Gems got certified as a registered Associate member of The Gems & Jewellery Export Promotion Council (GJEPC), India. GJEPC is an apex body sponsored by Ministry of Commerce, India. It promotes and facilitates India’s export in the gem and jewellery sector.<br /> <br /> 

We are grateful to our customers and suppliers for believing in us and helping us achieve these milestones. And, we look forward with keen positivity what future has in store for us. Thank you.<br /><br /> 


Founder & Owner,<br />

Kamal Kishore Agarwal<br />

Shri Govind Devji Gems
            </p>
          </div>
          <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src={photo}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
