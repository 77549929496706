import './App.css';
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import About from './Components/About'
import Contact from './Components/Contact'
import Home from './Components/Home'
import Gems from './Components/Gems'
import Jewellery from './Components/Jewellery'
import  { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <>
      <Navbar/>
      <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gems" element={<Gems />} />
          <Route path="/jewellery" element={<Jewellery />} />
          <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
