import React from 'react'
import logo from '../logo.jpeg'
const Fotter = () => {
  return (
    <footer class="text-gray-600 body-font">
  <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
  <img className='w-20' src={logo} alt="" />
    <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2023 Copyright reserved 
    </p>
    <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      +91 9314434825 <br />
      +91 9636907825 (Whatsapp) <br />
      kkagarwal@shrigovinddevjigems.com
      
    </span>
  </div>
</footer>
  )
}

export default Fotter