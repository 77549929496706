import React from "react";
import backgroundImage from "../ok.jpg";
import logo from "../logo.jpeg";
import gp1 from "./Photo/Stone/GP1.JPG";
import gp2 from "./Photo/Stone/GP2.JPG";
import gp3 from "./Photo/Stone/GP3.JPG";
import gp4 from "./Photo/Stone/GP4.JPG";
import gp5 from "./Photo/Stone/GP5.JPG";
import gp6 from "./Photo/Stone/GP6.JPG";
import gp7 from "./Photo/Stone/GP7.JPG";
import gp8 from "./Photo/Stone/GP8.JPG";
import "./Home.css";

const Home = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className="backdrop-brightness-50 mt-[60px] pt-20 bg-center bg-cover min-h-min flex flex-col text-[#ffffff] relative"
      >
        <div className="backdrop-blur-sm backdrop-brightness-75 bg-[#06010D]/30 absolute top-0 left-0 w-full h-full "></div>
        <main className="flex flex-col justify-center items-center container mx-auto  flex-1 text-center relative z-10">
          <h1 className="font-serif text-3xl md:text-6xl lg:text-8xl uppercase ">
            Shri Govind devJi Gems
          </h1>
          <p className="md:pt-10 font-Alice text-base md:text-lg lg:text-3xl mb-4 pt-5">
            GEMS | JEWELLERY
          </p>
          <p className="pb-20 md:pt-10  flex font-Alice text-lg md:text-2xl justify-center">
            Discover Elegance <br /> Your Premier Destination for Certified Gems
            & Jewellery.
          </p>
        </main>
      </div>
      <section class="text-gray-600 body-font">
        <div class="container md:px-28 px-5  pt-24 mx-auto">
          <div class="flex flex-wrap w-full mb-20">
            <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 class="sm:text-5xl text-5xl font-medium title-font font-serif mb-2 text-gray-900">
                Gem Stones
              </h1>
              <div class="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
          </div>
          <div class="flex-container flex-wrap justify-center">
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp1}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated (IGI)
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp2}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 2
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Zambian Emerald
                </h2>
                <p class="leading-relaxed text-base">
                  <br /> Weight : 4.46 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp3}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 3
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                African Hessonite Garnet
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 17.95 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 4
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Sri Lankan Blue Sapphire
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 9.23 cts <br /> Heated (IIGJ)
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp5}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 5
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Japanese Coral
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 19.58 cts <br /> Untreated <br /> Origin subject to testing
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp6}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 6
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Australian Opal
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 7.05 cts (Pair) <br /> Unheated and Untreated <br /> Play of colours both side
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp7}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 7
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  South Sea Pearl
                </h2>
                <p class="leading-relaxed text-base">
                  <br /> Weight : 23.64 cts <br /> Cultivated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp8}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 8
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                Sri Lankan Yellow Sapphire
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 8.03 cts <br /> Unheated and Untreated (IIGJ)
                </p>
              </div>
            </div>
            
          {/* </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container md:px-28 px-5 py-24 mx-auto">
          <div class="flex flex-wrap w-full mb-20">
            <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 class="sm:text-5xl text-5xl font-medium title-font font-serif mb-2 text-gray-900">
                Jewellery
              </h1>
              <div class="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
          </div>
          <div class="flex-container flex-wrap justify-center">
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp1}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp2}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp3}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div>
            <div class="xl:w-1/4 md:w-1/2 p-4">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="h-40 rounded w-full object-cover object-center mb-6"
                  src={gp4}
                  alt="content"
                />
                <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                  GP 1
                </h3>
                <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                  Burma Natural Ruby
                </h2>
                <p class="leading-relaxed text-base">
                  Weight : 3.10 cts <br /> Unheated and Untreated
                </p>
              </div>
            </div> */}
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
