import React from "react";

const Contact = () => {
  return (
    <>
      <section class="text-gray-600 body-font relative">
        <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <h2 class="font-serif text-gray-900 text-5xl mb-10 font-medium title-font">
              Contact us
            </h2>
            <div class="h-1 w-20 mb-10 bg-indigo-500 rounded"></div>
            <p class="font-serif leading-relaxed mb-5 text-base text-gray-600">
              At Shri Govind Devji Gems, we value your questions, feedback, and
              inquiries. Whether you're looking for that perfect piece of
              jewelry, need assistance with a recent purchase, or simply want to
              share your thoughts, we're here to assist you. <br /> <br /> We're
              just a phone call or email away to assist you with any questions
              or concerns you may have. Whether it's about our jewelry
              collections, gemstone varieties, custom designs, or anything else,
              we're here to provide you with the information you need.
            </p>
          </div>
          <div class="lg:w-2/3  md:w-1/2 min-h-10 bg-gray-300 rounded-lg overflow-hidden sm:ml-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.5109507515604!2d75.82256107540937!3d26.919011759819895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db6b4886ca187%3A0x40099f5ce567fa81!2sShri%20Govind%20Devji%20Gems!5e0!3m2!1sen!2sin!4v1694594974072!5m2!1sen!2sin"
            ></iframe>

            <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md mt-11">
              <div class="lg:w-1/2 px-6">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p class="mt-1 ">
                  Shri Govind Devji Gems Shop No 104 Pitaliyon, Bordi Ka Rasta,
                  Johri Bazar, Jaipur, Rajasthan 302003
                </p>
              </div>
              <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a class="text-indigo-500 leading-relaxed">kkagarwal@shrigovinddevjigems.com</a>
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p class="leading-relaxed">+91 9314434825 <br /> +91 9636907825 (Whatsapp)</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
